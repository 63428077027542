import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface SpecialisationObj {
  name: string;
}

const AddSpecialisationModal = () => {

  function isAxiosError(error: any): error is AxiosError {
    return error.isAxiosError === true;
  }
  
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: SpecialisationObj = {
    name: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Specialization name is required"),
  });

  const handleSubmit = async (specialisation: SpecialisationObj) => {
    setIsSubmitting(true);

    try {
      await axiosInstance.post(
        `/admin/specialization/upsert`,
        { name: specialisation.name },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      );

      queryClient.invalidateQueries(["getSpecialisations"]);
      dispatch(addToast({ kind: SUCCESS, msg: "Specialization added successfully" }));
      dispatch(hideModal());
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const { msg } = error.response.data;
        dispatch(addToast({ kind: ERROR, msg }));
      } else {
        dispatch(addToast({ kind: ERROR, msg: "Oops, something went wrong" }));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form className="px-6 py-4 mt-2" style={{ minWidth: "360px", maxWidth: "760px" }}>
        <Input label="Specialization Name" id="name" name="name" type="text" />

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn text="Save" isSubmitting={isSubmitting} classes="text-sm" />
        </div>
      </Form>
    </Formik>
  );
};

export default AddSpecialisationModal;
