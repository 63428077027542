import { APIProvider, ControlPosition, Map, MapControl, MapEvent, Marker } from "@vis.gl/react-google-maps";
import { useFormikContext } from 'formik';
import { GOOLGLE_MAPS_API_KEY } from '../../config';

function CenterMarker({ latName, lngName, precision = 5 }: { latName: string, lngName: string, precision?: number }) {
    const formref = useFormikContext();
    const { setFieldValue, values }: {
        values: any, setFieldValue: (name: string, value: string) => void
    } = formref;

    const splitPosition = (position: string, index: number) => {
        return position.split(".").at(index);
    }
    return (
        <div className="w-full h-96 rounded-xl">
            <APIProvider apiKey={GOOLGLE_MAPS_API_KEY as string}>
                <Map
                    style={{ width: '100%', height: '100%' }}
                    defaultCenter={{ lat: 0, lng: 0 }}
                    defaultZoom={3}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    id="map-id"
                    onZoomChanged={(e: MapEvent) => {
                        console.log("zoom", e.map.getZoom());
                        const center = e.map.getCenter();
                        const lat = center?.lat();
                        const lng = center?.lng();
                        setFieldValue(latName, lat?.toFixed(precision) as string);
                        setFieldValue(lngName, lng?.toFixed(precision) as string);
                    }}
                    onDrag={(e: MapEvent) => {
                        const center = e.map.getCenter();
                        const lat = center?.lat();
                        const lng = center?.lng();
                        console.log("drag", lat?.toFixed(precision) as string, lng?.toFixed(precision) as string);
                        setFieldValue(latName, lat?.toFixed(precision) as string);
                        setFieldValue(lngName, lng?.toFixed(precision) as string);
                    }}
                >
                    <MapControl position={ControlPosition.TOP_LEFT}>
                        <Marker
                            position={
                                { 
                                    lat: values[splitPosition(latName, 0) ?? ""] ? 
                                    parseFloat(values[splitPosition(latName, 0) ?? ""][splitPosition(latName, 1) ?? ""]) 
                                    : 0, 
                                    
                                    lng: values[splitPosition(lngName, 0) ?? ""] ? 
                                    parseFloat(values[splitPosition(lngName, 0) ?? ""][splitPosition(lngName, 1) ?? ""]) : 
                                    0 
                                }
                            }
                            draggable={false}
                        />
                    </MapControl>
                </Map>
            </APIProvider>
        </div>
    )
}

export default CenterMarker