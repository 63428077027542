import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import SelectDropDownAsync from "../FormikComponents/SelectDropDownAsync";

interface AppointmentObj {
    appointment_date: string;
    vet_id: string;
}

const AddAppointmentModal = () => {

    function isAxiosError(error: any): error is AxiosError {
        return error.isAxiosError === true;
    }

    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialValues: AppointmentObj = {
        appointment_date: "",
        vet_id: "",
    };

    const validationSchema = Yup.object({
        appointment_date: Yup.date().required("Appointment Date name is required"),
        vet_id: Yup.string().required("Vet Name is required"),
    });

    const handleSubmit = async (appointment: AppointmentObj) => {
        setIsSubmitting(true);

        try {
            await axiosInstance.post(
                `/admin/appointment/create`,
                { appointment_date: appointment.appointment_date, vet_id: appointment.vet_id },
                {
                    headers: {
                        ContentType: "application/json",
                    },
                }
            );

            queryClient.invalidateQueries(["getAllAppointments"]);
            dispatch(addToast({ kind: SUCCESS, msg: "Appointment Created Successfully" }));
            dispatch(hideModal());
        } catch (error) {
            if (isAxiosError(error) && error.response) {
                const { msg } = error.response.data;
                dispatch(addToast({ kind: ERROR, msg }));
            } else {
                dispatch(addToast({ kind: ERROR, msg: "Oops, something went wrong" }));
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values)}
        >
            <Form className="px-6 py-4 mt-2" style={{ minWidth: "360px", maxWidth: "760px" }}>
                <Input
                    label="Appointment Date"
                    type="date"
                    name="appointment_date"
                    // placeholder="From"
                    id="appointment_date"
                    className="border rounded-xl border-yellow-500 px-2 py-1.5 w-32"
                />

                <SelectDropDownAsync
                    label="Vet Name"
                    id="vet_id"
                    name="vet_id"
                    classes="block w-full rounded-md shadow-sm focus:ring-yellow-500 focus:border-yellow-500"
                    loadOptions={async (inferString: string) => {
                        try {
                            const response =
                                await axiosInstance.get("/admin/veterinary/all");
                            let requiredData: any = [];
                            for (
                                let i = 0;
                                i < response?.data?.data?.veterinaries?.length;
                                i++
                            ) {
                                if (response?.data?.data?.veterinaries[i].name.toLowerCase().replace(" ", "").includes(inferString.toLowerCase())) {
                                    requiredData.push({
                                        label: response?.data?.data?.veterinaries[i].name,
                                        value: response?.data?.data?.veterinaries[i].vet_id,
                                    });
                                }
                            }
                            return requiredData;
                        } catch (error) {
                            return [
                                {
                                    label: "Error. Something went wrong!!",
                                    value: "error",
                                },
                            ];
                        }
                    }}

                />



                <div className="buttons flex items-center w-full justify-center my-4">
                    <SubmitBtn text="Save" isSubmitting={isSubmitting} classes="text-sm" />
                </div>
            </Form>
        </Formik>
    );
};

export default AddAppointmentModal;
