import axios, { AxiosError } from "axios";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import SubmitBtn from "../FormikComponents/SubmitBtn";

const isAxiosError = (error: unknown): error is AxiosError => {
  return axios.isAxiosError(error);
};

interface UpdateScanCountModalProps {
  userId: string;
  scans_count: number; // Initial scan count from props
}

const UpdateScanCountModal = ({
  userId,
  scans_count,
}: UpdateScanCountModalProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSubmit = async (values: { scanCount: number }) => {
    try {
      await axiosInstance.patch(
        `/admin/user/update/${userId}`,
        { scans_count: values.scanCount },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      );

      queryClient.invalidateQueries(["getAllUsers"]);
      dispatch(
        addToast({ kind: SUCCESS, msg: "Scan count updated successfully." })
      );
      dispatch(hideModal());
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const { msg } = error.response.data;
        dispatch(addToast({ kind: ERROR, msg }));
      } else {
        dispatch(
          addToast({ kind: ERROR, msg: "An error occurred. Please try again." })
        );
      }
    }
  };

  return (
    <Formik
      initialValues={{ scanCount: scans_count }}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{ minWidth: "360px", maxWidth: "760px" }}
        >
          {/* Increment/Decrement Control */}
          <div className="flex items-center justify-between mb-4">
            <label
              htmlFor="scanCount"
              className="block text-sm font-medium text-gray-700"
            >
              Current Scan Count
            </label>
            <div className="flex items-center">
              <button
                type="button"
                onClick={() =>
                  setFieldValue("scanCount", Math.max(0, values.scanCount - 1))
                }
                className="px-3 py-1 bg-gray-300 text-gray-700 rounded-l"
              >
                -
              </button>
              <input
                id="scanCount"
                name="scanCount"
                type="number"
                value={values.scanCount}
                onChange={(e) =>
                  setFieldValue("scanCount", Number(e.target.value))
                }
                className="w-16 text-center border border-gray-300"
              />
              <button
                type="button"
                onClick={() =>
                  setFieldValue("scanCount", values.scanCount + 1)
                }
                className="px-3 py-1 bg-gray-300 text-gray-700 rounded-r"
              >
                +
              </button>
            </div>
          </div>

          {/* Submit Button */}
          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Update"
              isSubmitting={false}
              classes="text-sm"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateScanCountModal;
