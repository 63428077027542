import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { MdLogin } from "react-icons/md";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import mbbLogo from '../../assets/mbbLogo.png';
import axiosInstance from "../../axiosInstance";
import ErrorBox from "../../components/FormikComponents/ErrorBox";
import Input from "../../components/FormikComponents/Input";
import Select from "../../components/FormikComponents/Select";
import SubmitBtn from "../../components/FormikComponents/SubmitBtn";
import { loginUser } from "../../redux/features/authSlice";
import { showModal } from "../../redux/features/modalSlice";
import { ADD_VET_MODAL } from "../../types/constants";

interface UserObj {
  username: string;
  password: string;
  role: string;
}

const Login = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initalValues: UserObj = {
    username: "",
    password: "",
    role: ""
  };
  const [commonError, setCommonError] = useState("");

  const validationSchema = Yup.object({
    username: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    role: Yup.string().required("Role is required")
  });

  const handleSubmit = useCallback((user: UserObj) => {
    setIsSubmitting(true);

    axiosInstance
      .post(`/user/auth/login`, user, {
        headers: {
          ContentType: "application/json",
        },
      })
      .then((response) => {
        const data = response?.data?.data;
        console.log(data)

        setCommonError("");

        setIsSubmitting(false);

        dispatch(
          loginUser({
            accessToken: data.token,
            refreshToken: "",
            role: data.role || "veterinary",
            user: {
              _id: data.vet_id,
              email: data.email,
              username: data.name ? data.name : "User",
              profile: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEXk5ueutLeqsbTn6eqpr7PJzc/j5ebf4eLZ3N2wtrnBxsjN0NLGysy6v8HT1tissra8wMNxTKO9AAAFDklEQVR4nO2d3XqDIAxAlfivoO//tEOZWzvbVTEpic252W3PF0gAIcsyRVEURVEURVEURVEURVEURVEURVEURVEURVEURflgAFL/AirAqzXO9R7XNBVcy9TbuMHmxjN6lr92cNVVLKEurVfK/zCORVvW8iUBnC02dj+Wpu0z0Y6QlaN5phcwZqjkOkK5HZyPAjkIjSO4fIdfcOwFKkJlX4zPu7Ha1tIcwR3wWxyFhRG6g4Je0YpSPDJCV8a2Sv2zd1O1x/2WMDZCwljH+clRrHfWCLGK8REMiql//2si5+DKWKcWeAGcFMzzNrXC/0TUwQ2s6+LhlcwjTMlYsUIQzPOCb7YBiyHopyLXIEKPEkI/TgeuiidK/R9FniUDOjRDpvm0RhqjMyyXNjDhCfIMYl1gGjIMIuYsnGEYRMRZOMMunaLVwpWRW008v6fYKDIzxCwVAeNSO90BJW6emelYBRF/kHpYGVaoxTDAaxOFsfP9y8hpJ4xd7gOcij7JNGQ1EYFgkPJa1jQEiYZXRaRINKxSDUW9n+FT82lSKadkiru9/4XPqSLWOekGPoY05TAvLm9orm+YWuwHoBHkZKijNBJGmeb61eL6Ff/6q7bLr7yvv3vKGhpDRjvgjGaPz+gUg6YgcvpyAR2FIZ9U6nEEyZRTovmEU32KichpGn7C17XrfyH9gK/c0CMP05HZIM2uf9sEveizKveBy9/6Qt7o89ne33D525cfcIMW6ab+TMEukQbQbu+xu7X3A9bChmWaCeAkG17bpntwXgWxHaMzGPmUaR5dQZiKqRVeUZ3047fi3nAu28h4CHxCsZAgmEH8Y27jJAhm8c+5RQzRQNVGhVFSfxOYIjp/pP7RxzjevYXVGf4eLt+BJ1vCuLuLkrgABgCGXZ2wik5uty+oBvNirI6mkzhAf4Gsb58Hcm67Jzd+KwD10BYPLL3e0MjvKrgAULnOfveF/O4N2Xb9BZom3gJes3F9X5Zze8/6Yt09b4CrqsEjUv8oFBaR2rl+6CZr2xVrp24o/WitBKuGrrpl1+bFkmK2qXTON4VpbdfLa7o7y/WdLxG7lm2Lqh2clOwTegbvc/vj2U78CwhA87Bn8G5Nk3eOb0Nsr9flz3sG78UUtue4kpv1xvjg3TMay62BMlTlP+vrOMnJsRmt/ze0jsfkPPYdAH57hK+34PeOyc8XIXu5xT2HsUkdZz+adwg8HGFfQ3K5jtDvbUiO4Di9/ywHGrL88pDizZ++oTp+an+SMX/ndymUCwmHMdO7yuOx83pUx/eEMU0AvxWndwgidAqOZ8ypCwdEfvvEo6D9HwpA8wzvmOJEqAg9ySu8g4x0Hb9hSB/BANEKJ+LbPBU0lzbAJs4xt1AoshKkUGQmiH8/jJ0gdhTTLmSegHlPE0oOdXALnqDjKYh3px//fSgSWG8UqfrrIICzYYSJXRr9BSPbpNzw7gBjKjKOYI7ReIGqQRIap5+5MdjyvuDkExvGeXSlONWZAP3/AZBwJohU7QJRGU+cTVH18ELmRPNBmibW6MT/k1b0XhdkRBvyT6SB6EYv/GvhSmRNpGngRULsAlxMCGNXp7w3FfdEbTEEDdLI9TdIKRUzUesa3I461ER8cpNT7gMRhpKmYVS9ELOgCUQsa4SsulciKiLbY+AnHD8cpuhISsnxpamI84sbDq9qYJgf8wiiOBrC7Ml7M7ZECCqKoiiKoiiKoiiKoijv5AvJxlZRyNWWLwAAAABJRU5ErkJggg==",
              role: data.role,
            },
          })
        );
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 403:
            case 500:
              setCommonError(msg);
              break;
            default:
              setCommonError("Oops, something went wrong");
              break;
          }
        } else if (error.request) {
          setCommonError("Oops, something went wrong");
        } else {
          setCommonError(`Error: ${error.message}`);
        }
      });
  }, []);

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
        style={{
          minWidth: "360px",
        }}
      >
        <img  src={mbbLogo} width={100}/>
        <Input label="Email" id="username" name="username" type="email" />
        <Input label="Password" id="password" name="password" type="password" />
        <Select id="role" name="role" label="Select your role" options={[
          {label: "Vet", value: "veterinary"},
          {label: "Admin", value: "admin"}
        ]}
        classes="w-full"/>

        {commonError && (
          <div className="common-error mb-2 text-center">
            <ErrorBox msg={commonError} />
          </div>
        )}

        <div className="buttons flex items-center w-full justify-center my-4 mb-6">
          <SubmitBtn
            text="Login"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>

        <button
            className="btn-primary flex items-center justify-center w-full"
            type="button"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_VET_MODAL,
                  modalTitle: "Onboard a Vet",
                })
              );
            }}
          >
            <span className="mr-1">Sign Up as Vet</span> <MdLogin size={17} />
          </button>

        {/* <div className="forgot-password mb-4 text-sm">
          <Link to="/forgot-password" className="text-primary">
            Forgot Password?
          </Link>
        </div> */}
      </Form>
    </Formik>
  );
};

export default Login;
