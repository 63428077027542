import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Error404 from "../pages/Error404";

import { RootState } from "../redux/app";
import Header from "../components/Header/Header";
import Loader from "../components/Loader/Loader";
import Sidebar from "../components/Sidebar/Sidebar";
import UsersLayout from "./UsersLayout";
import SpecializationLayout from "./SpecilisationLayout";
import AppointmentLayout from "./AppointmentLayout";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const AllUsers = lazy(() => import("../pages/users/AllUsers"));
const UserVets = lazy(() => import("../pages/users/UserVets"));
const Specialisation = lazy(() => import("../pages/Specialisation/Specialisation"));
const AllAppointments = lazy(() => import( "../pages/Appointments/AllAppointments"));


const AdminLayout = () => {
  const { show } = useSelector((state: RootState) => state.sidebar);

  return (
    <div className="layout relative">
      <div className="layout__content flex">
        <Sidebar />

        <main
          className={`main flex-1 flex flex-col ${
            show ? "ml-60" : "ml-20"
          } transition-all`}
          style={{
            minHeight: "100vh",
          }}
        >
          <Header />

          <div className="page-wrapper flex-1 mt-4 px-12 pl-8 pb-24 box-border">
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Dashboard />} />

                <Route path="/users" element={<UsersLayout />}>
                  <Route index element={<AllUsers />} />
                  <Route path="vets" element={<UserVets />} />
                </Route>

                <Route
                  path="/specialisations"
                  element={<SpecializationLayout />}
                >
                  <Route index element={<Specialisation />} />
                  {/* <Route path="vets" element={<UserVets />} /> */}
                </Route>

                <Route path="/appointments" element={<AppointmentLayout/>}>
                  <Route index element={<AllAppointments />} />
                </Route>
                {/* /404 */}
                <Route path="/404" element={<Error404 />} />
                <Route
                  path="*"
                  element={<Navigate to="/404" replace={true} />}
                />
              </Routes>
            </Suspense>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
