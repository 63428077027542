import jwtDecode, { JwtPayload } from "jwt-decode";
import { UserObj } from "../types";

export const checkTokens = (): boolean => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");

    if (!refreshToken && !accessToken) {
      return false;
    }

    // first check, if you have a valid access_token
    if (accessToken) {
      // accessToken may be invalid, or expired, or no refreshToken or refreshToken present or refreshToken may be invalid
      try {
        // decode the token
        // invalid or malformed token will throw error
        const atoken = jwtDecode<JwtPayload>(accessToken as string);
        let exp = null;

        if (atoken && atoken?.exp) {
          exp = atoken.exp;
        }

        // if no exp date or expired exp date
        if (!exp || exp < new Date().getTime() / 1000) {
          // invalid accessToken
          // now check for refreshToken
          if (refreshToken) {
            const rtoken = jwtDecode<JwtPayload>(refreshToken as string);
            let exp = null;

            if (rtoken && rtoken?.exp) {
              exp = rtoken.exp;
            }

            // if no exp date or expired exp date
            if (!exp || exp < new Date().getTime() / 1000) {
              return false;
            }
          } else {
            return false;
          }
        }
      } catch {
        // invalid accessToken
        // now check for refreshToken
        if (refreshToken) {
          const rtoken = jwtDecode<JwtPayload>(refreshToken as string);
          let exp = null;

          if (rtoken && rtoken?.exp) {
            exp = rtoken.exp;
          }

          // if no exp date or expired exp date
          if (!exp || exp < new Date().getTime() / 1000) {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      // we have refreshToken
      // check if refreshToken exists or not
      const rtoken = jwtDecode<JwtPayload>(refreshToken as string);
      let exp = null;

      if (rtoken && rtoken?.exp) {
        exp = rtoken.exp;
      }

      // if no exp date or expired exp date
      if (!exp || exp < new Date().getTime() / 1000) {
        return false;
      }
    }

    // valid token
    return true;
  } catch (e) {
    return false;
  }
};

export const getTokens = () => {
  // check if the user has a valid or a access_token refresh_token
  if (checkTokens()) {
    return {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
      role: localStorage.getItem('role'),
      user: JSON.parse(localStorage.getItem("user") as string),
    };
  }

  removeTokens();
  return {
    accessToken: null,
    refreshToken: null,
  };
};

export const saveTokens = (accessToken: string, refreshToken: string, role: string, user:UserObj): void => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("role", role);
  localStorage.setItem("user", JSON.stringify(user));
};

// fn to save new access token
export const saveAccessTokens = (accessToken: string): void => {
  localStorage.setItem("accessToken", accessToken);
};

// fn to remove tokens
export const removeTokens = (): void => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("role");
  localStorage.removeItem("user");
};

// slice chars
export const chopChars = (maxLength: number, text: string) => {
  return text?.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

//function to convert ISOString Date to readable date and time
export const convertDate = (date: string) => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const year = d.getFullYear();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  const seconds = d.getSeconds();

  return `${day}/${month}/${year} `;
};

export const getBalance = (id: string, data: []): any => {
  const balance = data.find((item: any) => {
    if (item.customerId === id) return item.balance;
  });
  return balance;
};
export const getBalancePending = (id: string, data: []): any => {
  return data.find((item: any) => {
    if (item.customerId === id) {
      return item.customerId;
    } else {
      return null;
    }
  });
};

//localestring to yyyy-mm-dd
export const localestringToDate = (date: string) => {
  if (!date) return "";
  const d = new Date(date);
  const month = d.getMonth() < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const year = d.getFullYear();

  return `${year}-${month}-${day}`;
};

export const numberToIndianSysten = (amount: number) => {
  const convertedAmount = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
    style: "currency",
    currency: "INR",
  }).format(amount);

  if (convertedAmount == "₹NaN") return "";
  return convertedAmount;
};
